

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


html,
body
{
    overflow-x: hidden;
    /* background-color: #d2b48c; */
    background-color: tan;
}

* {
    cursor: none;
}

#cursors {
    position: absolute;
    left: -100%;
}

.cursor {
    width: 23px;
    height: 23px;
    border-radius: 100%;
    background-color: black;
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(calc(-50% + 15px), -50%);
    z-index: 4;
    /* display:hidden; */
  }
  
  .cursor2 {
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: white;


    /* opacity: .3; */
    position: fixed;
    transform: translate(-50%, -50%);
    pointer-events: none;
    transition: width .3s, height .3s, opacity .3s;
    z-index: 4;
    /* display:hidden; */

  }

/* .cursor {
    width: 30px;
    height: 30px;
    
    background-color: rgba(255, 255, 255, 0.4);
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);

    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    transform: translate(50%, -50%);
    z-index: 4;
  }
  
  .cursor2 {
    width: 10px;
    height: 10px;
    background-color: rgb(0, 0, 0);
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
    position: fixed;
    transform: translate(-45%, -50%);
    pointer-events: none;
    transition: width .3s, height .3s, opacity .3s;
    z-index: 4;

  } */


#topcontainer {

   display: flex;  
   /* flex-direction: column; doe dit als scherm klein is */
   align-items: flex-end;
   /* align-content: center; */
   justify-content: center;
   /* max-width: 1300px; */
   /* margin:auto; */
   margin-top: -30px;
   /* width: 100%; */

}





button {
    background-color: transparent;
    border: 3px solid black;
    color: black;
    padding-bottom: 8px;
    margin-bottom: 2vh;
    text-align: center;
    line-height: 100%;
    /* padding-top: 0; */
    text-decoration: none;
    display: inline-block;
    font-size: 2em;
    font-family: 'Sansita', sans-serif;
    border-radius: 13px;
    width: 100px;
    transition: box-shadow .3s, background-color .9s;
  }

  
button:hover {
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, .4);
}

.highlighted {
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 1);
    order: -1;
}

.active {
    /* background-color: white; */
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 1);
}


#bottomcontainer {
    display: flex;  
    /* display: none; */
    align-items: center;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: space-evenly;
    max-width: 1500px;
    margin: auto;
}

#aboutcontainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: center;
    max-width: 1000px;
    margin: auto;
    margin-top: 40px;
}

#aboutText {
    font-size: 1.3rem; width: 100%; line-height: 150%; max-width: 500px; padding: 0
}

#aboutimg {
    width: 50%;
    animation: vibrate 2s infinite;
    animation-timing-function: linear;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);   
}

@keyframes vibrate {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-1deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(1deg); }
    100% { transform: rotate(0deg); }
  }

#minimize {
    width: 37px;
    height: 37px;
    display: inline-block;
    /* border: 3px solid black; */
    border-radius: 13px;
    position: relative;
    top: 3px;
    /* float: right;
    float: top;
    top: -125px;
    right: 125px; */
    /* right: 5vw; */
    /* left: 150px;  */
    background-image: url(https://i.imgur.com/8e7xBxG.png);
    background-size: contain;
    transition: box-shadow .3s, background-color .9s;
}

#minimize:hover {
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, .4);
}

#fill {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* background-color: black; */
    /* background-color: black; */
    /* background-color: linear-gradient(180deg,black 0%, #d2b48c, 100%);
     */
    border-radius: 6vw 6vw 0px 0px;
    /* background: tan; */
    background: linear-gradient(180deg, #C49C67 0%, tan 100%);
    color: black;
    margin: auto;
    margin-bottom: 1em;
    margin-top: 1em;
    /* text-align: center; */
    font-family: 'Sansita', sans-serif;
    font-size: 2em;  
    opacity: 0;
    width: 100%;
    overflow: hidden;
    /* height: 1000px; */

 
}

#innerFill {
    max-width: 1200px;
    margin: auto;
    vertical-align: middle;
/*    padding-left: 10%;
    padding-right: 10%;*/
    /* justify-content: space-evenly; */
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 50px;

}


#upperGradient {
    background: white;
    background: linear-gradient(180deg, rgba(255, 255, 255, .4) 0%, rgba(210,180,140,0) 100%);
}

#title {
    color:black;
    padding: 2vh;
    padding-top: 30px;
    padding-bottom: 30px;
    /* width: 100%; */
    position: relative;

    display: inline-block;
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
    
    /* width: 100%; */
}

.cred {
    font-size: 80%;
    color: rgba(255, 255, 255, 0.4);
}

.description {
    font-size: 1.2rem;
    display: inline-block;
    font-family: 'Ubuntu', sans-serif;
    width: 45%;
    /* flex-grow: 2; */
    padding: 1em;
    padding-right: 4vw;
    height: 100%;
    line-height: 1.4;
    vertical-align: middle;
    justify-content: center;
}



#wrapperWrapper {
    display: inline-block;
    width: 40%;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 20px;
    overflow: hidden;
    /* float: right; */
    /* right: 0; */
}
.videoWrapper {
	position:relative;
    width: 100%;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
    background-color: rgba(255, 255, 255, 0);

}
.videoWrapper iframe, .videoWrapper object, .videoWrapper embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
    background-color: rgba(255, 255, 255, 0);

}

#inspirationBox {
    border: 10px dotted sandybrown;
    border-radius: 3vw;

    background: linear-gradient(270deg, rgba(255, 0, 0, 0.5), rgba(255, 182, 0, 0.5), rgba(249, 255, 0, 0.5), rgba(58, 255, 0, 0.5), rgba(0, 253, 255, 0.5), rgba(0, 95, 255, 0.5), rgba(141, 0, 255, 0.5), rgba(255, 0, 0, 0.5));
    background-size: 1600% 1600%;
    -webkit-animation: rainbow 10s linear infinite;
    -moz-animation: rainbow 10s linear infinite;
    animation: rainbow 10s linear infinite;
    /* background-color: rgba(0, 0, 0, 0.2); */
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin-top: 4vh;
}

#inspirationBox > *:not(#IBtitle):hover {
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 1);

}

.IBimg {
    display: inline-block;
    max-width: 40%;
    border-radius: 20px;
    /* object-fit: contain; */
}


#IBtitle {
    width: 100%;
    padding: 20px;
    align-self: flex-start;
    text-align: center;
    font-family: "Sansita", sans-serif;
    font-size: 2rem;
    -webkit-animation: color-change 1s infinite;
    -moz-animation: color-change 1s infinite;
    -o-animation: color-change 1s infinite;
    -ms-animation: color-change 1s infinite;
    animation: color-change 1s infinite;

} 



@-webkit-keyframes rainbow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes rainbow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes rainbow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@-webkit-keyframes color-change {
    0% { color: darkslateblue; }
    33% { color: purple; }
    66% { color: maroon}
    100% { color: darkslateblue; }
}
@-moz-keyframes color-change {
    0% { color: darkslateblue; }
    33% { color: purple; }
    66% { color: maroon}
    100% { color: darkslateblue; }
}
@-ms-keyframes color-change {
    0% { color: darkslateblue; }
    33% { color: purple; }
    66% { color: maroon}
    100% { color: darkslateblue; }
}
@-o-keyframes color-change {
    0% { color: darkslateblue; }
    33% { color: purple; }
    66% { color: maroon}
    100% { color: darkslateblue; }
}
@keyframes color-change {
    0% { color: darkslateblue; }
    33% { color: purple; }
    66% { color: maroon}
    100% { color: darkslateblue; }
}

.IBimg > img {
    width: 100%;
    border-radius: 20px;

}

#drag {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 110px;
    border-radius: 15px;
    z-index: 3;
    transition: box-shadow .3s;   
}


#drag:hover {
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 1);
}

#dragframe {
    background-image: url(https://i.imgur.com/36IkXoz.png);
    background-size: contain;
    width: 295px;
    height: 250px;
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 4vh;
    /* height: 48.5vw; */
}

/* .video {
    aspect-ratio: 16 / 9;
    margin: auto;
    max-height: 400px;
}

.videoWrapper {
} */


.project {
    display: flex;
    align-items: center;
    height: 200px;
    width: 200px;
    border-radius: 30px;
    background-image: url("https://i.imgur.com/2F3Al82.jpeg");
    /* background-repeat: no-repeat	; */
    background-size: 100%;
    background-position: center;
    background-color: gray;
    color: white;
    margin: 1em;
    margin-bottom: 1em;
    text-align: center;
    font-family: 'Sansita', sans-serif;
    font-size: 2em;   
    transition: box-shadow .3s, transform .1s;
}

.project:hover {
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 1);
    transform: scale(1.2)

}

#gallery {
    display: inline-flex;
    flex-wrap: wrap;
    /* height: 15vh; */
    justify-content: center;
    align-items: center;
    /* align-content: space-around; */
    gap: 20px;
    row-gap: 20px;
    max-width: 90%;
    background: white;
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%); */
    background: rgba(255,255,255,0.2);
    background-size: contain;
    padding: 1rem;
    border-radius: 15px;
}

#gallery > * {
    margin: 0px;
    background-color: rgba(255, 255, 255, 0);
    transition: box-shadow .3s;
    font-family: Ubuntu, sans-serif;
}

.fancybox__caption {
    font-family: Ubuntu, sans-serif;

}

#gallery > a > img {
    height: 210px;
}

#gallery > *:hover {
    box-shadow: 0 0 2vw .2vw rgba(255, 255, 255, 0.8);

}

#TTB {
    background-image: url("https://imgur.com/KiAQMCs.jpg");
}

#BTBB {
    background-image: url("https://i.imgur.com/WUW8NjO.jpg");
}

#DBT {
    background-image: url("https://i.imgur.com/PHCahuD.jpg");
}

#B {
    background-image: url("https://i.imgur.com/57PYEqZ.jpg");
}

#DM {
    background-image: url("https://i.imgur.com/FBPTeZ9.jpg");
}

#DTMD {
    background-image: url("https://i.imgur.com/ZaeFKGw.jpg");
}

#N {
    background-image: url("https://i.imgur.com/TbWwC2H.jpg");
}

#PM {
    background-image: url("https://i.imgur.com/riTIb7u.jpg");
}

#Z {
    background-image: url("https://i.imgur.com/OYOEAER.jpg");
}

#BTG {
    background-image: url("https://i.imgur.com/riTIb7u.jpg");
}

#BTG {
    background-image: url("https://i.imgur.com/riTIb7u.jpg");
}

#SS {
    background-image: url("https://i.imgur.com/lmSnDLX.jpg");
}

#L {
    background-image: url("https://i.imgur.com/E4bZUHM.jpg");
}

#SJS {
    background-image: url("https://i.imgur.com/rjXuPmg.jpg");
}

#MM {
    background-image: url("https://i.imgur.com/W9v0rYq.jpg");
}

#PP {
    background-image: url("https://i.imgur.com/pLpuo7f.png");
}


#FB {
    background-image: url("https://i.imgur.com/J77tFFS.png");
}

/* .project:hover {
    height: 240px;
    width: 240px;
    margin-bottom: 5px;
} */


    

.project > p {
    margin:auto;
    padding: 10%;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

}

.webgl {
    /* margin: auto; */
    display: inline;
    z-index: 3;
    /* padding-left: -20px; */
}

.kvdw, .ixd {
    display: inline-block;
    /* padding: 100px; */
    width: 500px;
    /* padding-top: 10px; */
    padding-bottom: 10px;
    margin-left: -80px;
    margin-right: -80px;
    z-index: 3;


}
.kvdw {
    text-align: right;
}

#nav {
    margin: auto;
    margin-top: 20px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    gap: 5vw;
}



h1 {
    font-family: 'Sansita', sans-serif;
    color: black;
    font-size: 48px;
    /* display: inline-block; */
}

.links {
    margin-top:1em;
    margin-left: 1em;
}


#socials {
    margin: auto;
    margin-top: 3vh;
    text-align: center;
    width: 100%;
    /* margin: 1em; */
    padding: none;

}



#IG, #LI, #SC, #SP, #TW {
    background-color: transparent;
    display: block;

    margin: 1em;
}

/* img {
    display: inline-block;

} */

li {
    float:left;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: inline-block;
    /* width: 33%; */
    
}

/* #circle {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    transition: background-color .5s;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 3.5vh;
    left: 0;
    right: 0;
    text-align: center;
} */

.cube {
    width: 15px;
    height: 15px;
    display: flex;
    background-color: #8CAAD2;
    border-radius: 20%;
    transition: opacity 8s;
    /* overflow: visible; */
}

.cube:hover {
    /* opacity: 0; */
    /* transition: opacity; */
    transform: scale(1.2);
}

#cubecontainer {
    max-width: 90%px;
    height: 100px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 8%;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0;
    /* overflow: hidden; */
    padding-bottom: 40px;
}

@media only screen and (max-width: 1400px) {
    #bottomcontainer {
        max-width: 90%;
        
    }

    #circle {
        width: 175px;
        height: 175px;
    }

    .outercube {
        display: none;
    }
    
}

@media only screen and (max-width: 1300px) {
    h1 {
      font-size: 40px;
      margin-bottom: .2em;
      align-items: flex-end;
    }

    #bottomcontainer {
        max-width: 90%;
        
    }

    .project {
        width: 180px;
        height: 180px;
        font-size: 1.6em;
    }

    #aboutimg {
        margin-top: 40px;
    }


    #inspirationBox {
        width: 80%
    }
    
}

@media only screen and (max-width: 945px) {
    #minimize {
        width: 25px;
        height: 25px;
        /* border: 3px solid black; */
        border-radius: 8px;
        /* top: 38px;
        left: 80px;  */
    }
    .row {
        flex-direction: column;
        margin-bottom: 20px;

    }

    #gallery > a > img {
        height: 150px;
    }

    #IBimg {
        width: 100px;
    }

    #title {
        padding-bottom: 10px;
        margin-bottom: -10px;
    }


    #aboutcontainer {
            flex-direction: column;

    }

    .row > * {
        width: 80%;
    }

    #wrapperWrapper {
        width: 80%;
        margin-top: 2vh;
    }

    #inspirationBox #wrapperWrapper {
        width: 40%;
    }
    
    h1 {
      font-size: 30px;
      margin-bottom: .2em;
    }

    #topcontainer {
        flex-direction: column;
        align-items: center;

        /* margin-top: 20px; */

    }

    img {
        width: 100%;
        height: 100%;
    }
    

    .kvdw, .ixd {
        order: 3;
        padding-bottom: 1vh;
        line-height: .7;
        text-align: center;
        width: 300px;
        z-index: 5;
    }

    .kvdw {
        margin-top: -20px;
    }


    li > a {
        height: 25px;
        width: 25px;
        margin: .45em;
    }

   

    .project {
        height: 160px;
        width: 160px;
        font-size: 1.5em;
        margin: .5em;
    }

    .innercube {
        display: none;
    }

    #circle {
        width: 140px;
        height: 140px;
        top: 5vh;
    }

  }

  @media only screen and (max-width: 600px) {
    #aboutText {
        font-size: 1rem;
        width: 80%;
    }

    #circle {
        width: 120px;
        height: 120px;
        top: 5vh;
    }

    .project {
        height: 150px;
        width: 150px;
        font-size: 1.4em;
        
    }

    #bottomcontainer {
        max-width: 95%;
        
    }

    #aboutimg, #inspirationBox {
        width: 80%;
    }

    #inspirationBox #wrapperWrapper {
        width: 40%;
    }

    #title {
        margin-bottom: -15px;
    }



  }



